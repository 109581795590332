
import { request as axios } from '@/util/request';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Moment } from 'moment';
import moment from 'moment';
import { PaginationData } from '@/api/operation/customer';
import { RefundData, initRefundData } from '@/api/rider/riderList';
import { setRegion } from '@/view/repository/region';
import storage from 'baimahu/storage';
import fileDownload from 'js-file-download';
import {
  initWarehouseData,
  WarehouseData,
  getWarehouseList,
  abexportData,
} from '@/api/order/order-management';
interface Form {
  ycdh: string; //异常单号
  ddbh: string; //订单编号
  knight_name: string; //骑手姓名
  order_time: Moment[]; //反馈时间
  reson: string; //异常原因
  warehouse_id: string;
}
const SEARCH_TYPE = [
  {
    id: 0,
    value: '骑手ID',
  },
  {
    id: 1,
    value: '骑手姓名',
  },
  {
    id: 2,
    value: '骑手电话',
  },
];
const REFUND_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待审核',
  },
  {
    id: 2,
    value: '审核通过',
  },
  {
    id: 3,
    value: '审核不通过',
  },
];
function getFormData() {
  return {
    warehouse_id: '',
    ycdh: '',
    ddbh: '',
    knight_name: '',
    order_time: [],
    reson: '',
  };
}
@Component({
  name:'Abnormal'
})
export default class Abnormal extends Vue {
  is_loading: boolean = false;
  warehouse_list: WarehouseData[] = initWarehouseData();
  audioPlay = false; //是否开始自动播放
  form: Form = getFormData();
  ware_category_list: any = []; //意向区域
  province_list: any = []; // 省
  city_list: any = []; // 市
  district_list: any = []; // 区
  area_list: any = []; // 区域列表
  chuliReson = ''; //处理结果
  chuliPeople = ''; //处理人
  chuliTime = ''; //处理时间
  chuli_dis: boolean = false;
  //搜索条件
  get searchType() {
    return SEARCH_TYPE;
  }
  //清空
  clear() {
    this.form.ycdh = '';
    this.form.ddbh = '';
    this.form.knight_name = '';
    this.form.order_time = [];
    this.form.reson = '';
    this.form.warehouse_id = '';
  }
  //骑手状态
  get refundStatus() {
    return REFUND_STATUS;
  }
  refund_data: PaginationData<RefundData> = initRefundData();
  //分页
  current = 1;
  paginationOption(data: any) {
    this.current = data.current || 1;
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  // 获取发货门店
  async getWarehouseList() {
    const res: any = await getWarehouseList();
    this.warehouse_list = initWarehouseData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.warehouse_list = initWarehouseData(res.data);
    const obj = {
      id: 0,
      name: '全部',
    };
    this.warehouse_list.unshift(obj as any);
  }
  //获取列表数据
  async getData(body?: any) {
    const bodys: any = {
      ...body,
      current: this.current,
      limit: 10,
    };
    const res: any = await axios.get('/boss/knight/order/exception', {
      params: {
        ...bodys,
      },
    });
    this.refund_data = res.data;
  }
  //搜索
  async find() {
    const start_time = this.form.order_time[0]
      ? Math.floor(+this.form.order_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.order_time[1]
      ? Math.floor(+this.form.order_time[1].format('x') / 1000)
      : '';
    const send_data: any = {
      code: this.form.ycdh,
      order_number: this.form.ddbh,
      warehouse_id: this.form.warehouse_id,
      time_start: start_time,
      time_end: end_time,
      knight_name: this.form.knight_name,
      reason: this.form.reson,
    };
    if (this.form.ycdh == '') {
      delete send_data.code;
    }
    if (this.form.ddbh == '') {
      delete send_data.order_number;
    }
    if (this.form.knight_name == '') {
      delete send_data.knight_name;
    }
    if (this.form.reson) {
      delete send_data.reson;
    }
    if (this.form.order_time.length <= 1) {
      delete send_data.time_end;
      delete send_data.time_start;
    }
    if (this.form.warehouse_id == '') {
      delete send_data.warehouse_id;
    }
    if (this.form.reson == '') {
      delete send_data.reason;
    }
    await this.getData(send_data);
  }
  //处理
  chuli_status: any;
  chuli_id: any;
  chuli(record: any) {
    //console.log(record)
    this.chuli_status = record.status;
    this.chuli_dis = true;
    this.chuli_id = record.id;
    /* const res: any=this.refund_data.detail.filter((item: any)=>{
      return item.id=this.chuli_id
    }) */
    this.chuliReson = record.result;
    this.chuliPeople = record.actor_name;
    this.chuliTime = moment(record.action_time * 1000).format('YYYY-MM-DD');
  }
  //处理确定
  async chuliOk() {
    console.log(this.chuliReson);
    if (this.chuliReson == '' || this.chuliReson == undefined) {
      this.$message.error('请填写处理结果');
      return false;
    } else {
      const res = await axios.put(`/boss/knight/order/exception/status/${this.chuli_id}`, {
        result: this.chuliReson,
      });
      if (res.status == 200) {
        this.$message.success('处理成功');
      } else {
        this.$message.error((res as any).message);
      }
      this.chuli_dis = false;
      await this.getData();
    }
  }
  //取消
  chuliCancel() {
    this.chuli_dis = false;
  }
  async mounted() {
    await setRegion(); // 设置 localStorage 中的 region_list
    this.province_list = storage.getItem('region_list');
    await this.getWarehouseList();
    await this.getData();
  }
  /* async created(){
    this.audioPlay=false
    window.setInterval(()=>{
        setTimeout(async ()=>{
          const res: any=await axios.get('/boss/knight/order/exception',{
          params:{
            current:1,
            limit:10
          }
        })
          if(res.data.detail!=this.refund_data){
            this.openNotification()
            this.audioPlay=true
          }
        },0)
      },5000)
      this.audioPlay=false
  } */
  //导出
  async exportData() {
    const start_time = this.form.order_time[0]
      ? Math.floor(+this.form.order_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.order_time[1]
      ? Math.floor(+this.form.order_time[1].format('x') / 1000)
      : '';
    const send_data: any = {
      code: this.form.ycdh,
      order_number: this.form.ddbh,
      warehouse_id: this.form.warehouse_id,
      time_start: start_time,
      time_end: end_time,
      knight_name: this.form.knight_name,
      reason: this.form.reson,
      limit: 10,
      current: this.current,
      is_download: 1,
    };
    if (this.form.ycdh == '') {
      delete send_data.code;
    }
    if (this.form.ddbh == '') {
      delete send_data.order_number;
    }
    if (this.form.knight_name == '') {
      delete send_data.knight_name;
    }
    if (this.form.reson) {
      delete send_data.reson;
    }
    if (this.form.order_time.length <= 1) {
      delete send_data.time_end;
      delete send_data.time_start;
    }
    if (this.form.warehouse_id == '') {
      delete send_data.warehouse_id;
    }
    if (this.form.reson == '') {
      delete send_data.reason;
    }
    /* const getUrl = require('baimahu/url-with-param');
    const { VUE_APP_API: FCAPI } = process.env;
    const url = getUrl(`${FCAPI}/boss/knight/order/exception`, send_data);
    window.location.href = url; */
    try {
      const r: any = await abexportData(send_data);
      if (r.status === 402) {
        this.$message.error(r.message);
      }
      console.log(r);
    } catch (error) {
      // window.location.href = `https:${error}`;
    }
  }
  openNotification() {
    this.$notification.open({
      message: '',
      duration: undefined,
      description: '您有新的异常订单',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  }
  yuyin() {
    this.audioPlay = true;
  }
}
